import PropTypes from 'prop-types';
import React from 'react';

import BackToTop from './back-to-top';
import Footer from './footer';
import Header from './header';

const Layout = ({ children, path }) => {
  return (
    <>
      <div className="relative grid-1440">
        <main className="main-wrapper oh">
          <Header path={path} />
          <div className="content-wrapper oh">
            {children}

            <Footer path={path} />
            <BackToTop />
          </div>
        </main>
      </div>
    </>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
};

export default Layout;
