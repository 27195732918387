export function trackGoal(name, props) {
  if (window.plausible) {
    window.plausible(name, props);
  } else {
    console.log(
      `Plausible is disabled. Track goal '${name}' with props ${JSON.stringify(
        props || {}
      )}."`
    );
  }
}
