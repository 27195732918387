import React from 'react';
import { useEffect, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const BackToTop = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="back-to-top" className={scroll ? 'show' : null}>
      <AnchorLink href="#top" title="Zurück nach oben">
        <i className="fa fa-angle-up"></i>
      </AnchorLink>
    </div>
  );
};

export default BackToTop;
