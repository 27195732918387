import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useEffect, useRef, useState } from 'react';

import { trackGoal } from '../utilities/plausible';

const Logo = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "logo-header.png" }) {
          childImageSharp {
            resize(height: 100) {
              src
            }
          }
        }
      }
    `
  );

  return (
    <img
      src={data.file.childImageSharp.resize.src}
      className="logo"
      alt="Logo"
    />
  );
};

const Header = ({ path }) => {
  const ref = useRef();

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [isNavCollapsing, setIsNavCollapsing] = useState(false);
  const [navHeight, setNavHeight] = useState(isNavCollapsed ? 0 : undefined);

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);

    setIsNavCollapsing(true);
    setTimeout(() => setIsNavCollapsing(false), 333);
  };

  useEffect(() => {
    if (isNavCollapsed) setNavHeight(0);
    else setNavHeight(ref.current?.getBoundingClientRect().height + 15);
  }, [isNavCollapsed]);

  return (
    <header className="nav-type-1 bg-dark" id="top">
      <nav className="navbar navbar-static-top">
        <div className="navigation" id="sticky-nav">
          <div className="container relative">
            <div className="row">
              <div className="navbar-header">
                <div className="logo-container">
                  <div className="logo-wrap">
                    <Link to="/">
                      <Logo />
                    </Link>
                  </div>
                </div>
                <button
                  type="button"
                  className={`navbar-toggle ${
                    isNavCollapsed ? 'collapsed' : ''
                  }`}
                  data-toggle="collapse"
                  data-target="#navbar-collapse"
                  onClick={handleNavCollapse}
                >
                  <span className="sr-only">Navigation umschalten</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
              </div>

              <div className="nav-wrap right">
                <div
                  id="navbar-collapse"
                  className={`navbar-collapse text-center ${
                    isNavCollapsing
                      ? 'collapsing'
                      : isNavCollapsed
                      ? 'collapse'
                      : 'collapse in'
                  }`}
                  style={{ height: navHeight }}
                >
                  <ul ref={ref} className="nav navbar-nav navbar-right">
                    <li className={path === '/ueber-uns/' ? 'active' : ''}>
                      <Link to="/ueber-uns/">Über uns</Link>
                    </li>

                    <li className={path === '/angebote/' ? 'active' : ''}>
                      <Link to="/angebote/">Angebote</Link>
                    </li>

                    <li className={path === '/preise/' ? 'active' : ''}>
                      <Link to="/preise/">Preise</Link>
                    </li>

                    <li className={path === '/news/' ? 'active' : ''}>
                      <Link to="/news/">News</Link>
                    </li>

                    <li>
                      <a
                        href="/termin-buchen"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                          trackGoal('Booking: Visit Form', {
                            props: { source: 'Navigation' },
                          })
                        }
                      >
                        Termin buchen
                      </a>
                    </li>

                    <li className={path === '/jobs/' ? 'active' : ''}>
                      <Link to="/jobs/">Jobs</Link>
                    </li>

                    <li className={path === '/kontakt/' ? 'active' : ''}>
                      <Link to="/kontakt/">Kontakt</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {
  path: PropTypes.string,
};

export default Header;
