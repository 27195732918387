import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

const Logo = () => {
  return (
    <StaticImage
      src="./../images/logo.png"
      height={100}
      placeholder="none"
      alt="Logo"
    />
  );
};

const Footer = ({ path }) => {
  return (
    <footer className="footer footer-type-4 bg-dark">
      <div className="container">
        <div className="footer-widgets pb-mdm-20">
          <div className="row">
            <div className="col-md-3 col-sm-12 col-md-push-6">
              <div className="widget footer-about-us">
                <h5 className="widget-title uppercase">Kontakt</h5>
                <address className="footer-address">
                  <i className="fa fa-map-marker"></i>{' '}
                  <strong>Coiffeur Cocoon</strong>
                  <br />
                  Jasmin Sheila Brechbühl
                  <br />
                  Bernstrasse 15
                  <br />
                  3600 Thun
                </address>
                <p>
                  <i className="fa fa-phone"></i>{' '}
                  <a href="tel:+41332211778">033 221 17 78</a>
                </p>
                <p>
                  <i className="fa fa-whatsapp"></i>{' '}
                  <a
                    href="https://api.whatsapp.com/send?phone=41332211778"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    033 221 17 78
                  </a>
                </p>
              </div>
            </div>

            <div className="col-md-3 col-sm-12 col-md-push-6">
              <div className="widget footer-about-us">
                <h5 className="widget-title uppercase">Öffnungszeiten</h5>
                <p>
                  <i className="fa fa-star-half-o"></i>
                  <strong>Montag, Mittwoch, Donnerstag</strong>
                  <br />
                  nach Absprache
                </p>
                <p>
                  <i className="fa fa-star"></i>
                  <strong>Dienstag, Freitag</strong>
                  <br />
                  08:00 - 18:30 Uhr
                </p>
                <p>
                  <i className="fa fa-star"></i>
                  <strong>Samstag</strong>
                  <br />
                  08:00 - 16:00 Uhr
                </p>
              </div>
            </div>

            <div className="col-md-6 col-sm-12 col-md-pull-6">
              <div className="widget footer-logo">
                <Logo />

                <div className="footer-socials style-2">
                  <div className="social-icons">
                    <a
                      href="https://www.facebook.com/coiffeurcocoon"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Facebook"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/coiffeurcocoon"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Instagram"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                    <a
                      href="https://www.twitter.com/coiffeurcocoon"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Twitter"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a
                      href="/rss.xml"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="RSS Feed"
                    >
                      <i className="fa fa-rss"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 copyright sm-text-center">
              <span>
                Copyright &copy; {new Date().getFullYear()} - Alle Rechte
                vorbehalten
              </span>
            </div>

            <div className="col-sm-6">
              <ul className="bottom-footer-links text-right sm-text-center">
                <li className={path === '/impressum/' ? 'active' : ''}>
                  <Link to="/impressum/">Impressum</Link>
                </li>
                <li className={path === '/haftungsausschluss/' ? 'active' : ''}>
                  <Link to="/haftungsausschluss/">Haftungsausschluss</Link>
                </li>
                <li className={path === '/datenschutz/' ? 'active' : ''}>
                  <Link to="/datenschutz/">Datenschutz</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  path: PropTypes.string,
};

export default Footer;
